.QuickActionLink {
  width: 174px;
  height: 174px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  padding: 16.5px;
  padding-top: 42px;
  gap: 32px;
  font-size: 18px;
  border: 1px solid var(--gray-400);
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-in-out;
  color: var(--gray-900);

  &:hover {
    background-color: var(--primary-50);
    border-color: var(--primary-700);
  }

  .__Icon {
    font-size: 48px;
    color: var(--primary-700);
  }
}
