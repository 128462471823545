.AuthFormContainer {
  width: 100dvw;
  height: 100dvh;
  background-image: url("../../../public/assets/img/bg5.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  .__Form {
    width: 25%;
    background-color: white;
    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 24px;
    padding-top: 7vh;

    .brand {
      text-align: center;
      height: 200px;
      margin-bottom: 5vh;
      .BrandLogo {
        height: 100%;

        img {
          height: 100%;
          width: auto;
          max-width: 200px;
        }
      }
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.125;
      margin: 0;
      color: var(--body2);
    }

    legend {
      margin-top: -16px;
      margin-bottom: 1.5rem;
      font-size: 16px;
      line-height: 1;
      color: var(--gray-900);
    }
  }
}
