.AppSkeleton {
  display: grid;
  grid-template-columns: var(--main-sidebar-width) auto;

  .MainNavigationBox {
    overflow-y: auto;
    height: 100dvh;
  }
  .MainPageContentBox {
    height: 100vh;
    overflow: hidden;
  }
}
