@import url("./color-palette.scss");
@import url("./roboto.font.scss");
@import url("./shadows.scss");

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--body2);
}
