.VlInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
  border: 1px solid var(--input-border);
  color: var(--body2);
  padding: 16px;
  height: 56px;

  .__Input {
    display: block;
    width: 100%;
    border: none;
    box-shadow: none;
    font-size: 18px;
    line-height: 1;
    padding: 0;
    outline: none;
    letter-spacing: 1px;

    &[type="password"]:not(:placeholder-shown) {
      height: 21px;
      letter-spacing: 5px;
      font-size: 36px;
      line-height: 18px;
      color: var(--gray-700);
    }

    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
