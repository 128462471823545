.NotificationsContainer {
  display: flex;
  width: 100%;
  height: 72px;
  color: var(--text-muted);
  // border: 1px solid var(--gray-500);
  align-items: center;
  justify-content: center;
  background-color: var(--gray-100);
  letter-spacing: 0.5px;
}
