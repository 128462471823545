.Appointments {
  .AppointmentsList,
  .NoAppointmentsMessage {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .NoAppointmentsMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-100);
    color: var(--gray-500);
  }
}
