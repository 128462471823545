@import url("theme/theme.scss");

* {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}
