// declare variables
:root {
  --body: #383e38;
  --body2: #030e23;
  --input-body: #637381;
  --text-muted: var(--gray-500);

  --white: #ffffff;
  --black: #000000;

  --light: #f9fafb;
  --dark: #161c24;

  --border: #aeaeae;
  --input-border: #919eab;

  --gray-100: #f9fafb;
  --gray-200: #f4f6f8;
  --gray-300: #dfe3e8;
  --gray-400: #c4cdd5;
  --gray-500: #919eab;
  --gray-600: #637381;
  --gray-700: #454f5b;
  --gray-800: #212b36;
  --gray-900: #161c24;

  --info: #1890ff;
  --info-100: #d0f2ff;
  --info-300: #74caff;
  --info-500: #1890ff;
  --info-700: #0c53b7;
  --info-900: #04297a;

  --success: #54d62c;
  --success-100: #e9fcd4;
  --success-300: #aaf27f;
  --success-500: #54d62c;
  --success-700: #229a16;
  --success-900: #08660d;

  --error: #ff4842;
  --error-100: #ffe7d9;
  --error-300: #ffa48d;
  --error-500: #ff4842;
  --error-700: #b72136;
  --error-900: #7a0c2e;

  --warning: #ffc107;
  --warning-100: #fff7cd;
  --warning-300: #ffe16a;
  --warning-500: #ffc107;
  --warning-700: #b78103;
  --warning-900: #7a4f01;

  --primary: #c6a15a;
  --primary-dark: #9e8148;
  --primary-50: #f6f2ea;
  --primary-100: #e8d9bd;
  --primary-300: #d7bd8c;
  --primary-500: #c6a15a;
  --primary-700: #b29151;
  --primary-900: #9e8148;
  --primary-hover: #cb914f;
}
