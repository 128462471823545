.SearchBarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 1px solid var(--border);
  padding: 0 18px;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 1px;
  gap: 16px 0.75rem;

  .__icon {
    width: 24px;
    aspect-ratio: 1/1;
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .SearchField {
    flex: 1 auto auto;
    font-size: inherit;
    border: none;
    height: 100%;
    line-height: 200%;
    color: var(--body);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--gray-600);
    }
  }
}
