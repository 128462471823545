.RecentTransactions {
  .TransactionsList,
  .NoTransactionsMessage {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .NoTransactionsMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-100);
    color: var(--gray-500);
  }
}
