.VlButton {
  height: 56px;
  display: block;
  width: 100%;
  box-shadow: none;
  font-size: 18px;
  line-height: 1;
  border-radius: 0px !important;
  outline: none;
  border: 1px solid var(--input-border);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;

  &[variant="contained"] {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);

    &:hover {
      background-color: var(--primary-hover);
    }

    &:active {
      outline: 1ps dashed var(--body2);
    }

    &:disabled {
      background-color: #f1e7d5;
      border-color: #f1e7d5;
      cursor: not-allowed;
    }
  }
}
