.HomeLayout {
  display: grid;
  width: 100%;
  grid-template-columns: 570px 1fr 1fr;
  grid-template-rows: minmax(400px, 500px) minmax(250px, 350px);
  gap: 24px;

  .HomeLayoutCard {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;

    .__title {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
