.MainNavigation {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 0rem 3rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
  flex-shrink: 0;

  // styles
  border-right: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  ._TopSection,
  ._MiddleSection,
  ._BottomSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;
  }

  .BrandLogo {
    width: 100%;
    display: flex;
    padding: 1.5rem 0rem;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      max-width: 12rem;
    }
  }

  .MainNavLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }

  ._NavLink {
    padding: 0rem 1rem 0rem 1.5rem;
    display: flex;
    width: 100%;
    text-decoration: none;
    color: inherit;

    .__base {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 16px;
      padding: 1rem 1.125rem 1rem 1rem;
      transition: all 0.3s ease-in-out;
      font-size: 20px;
      line-height: 22px;
    }

    .nav_icon {
      font-size: 24px;
      line-height: 1;
      padding: 0;
      width: 24px;
      height: 24px;

      .__default {
        display: unset;
      }

      .__active {
        display: none;
      }
    }

    .__title {
      font-size: 20px;
      line-height: 24px;
    }

    &:hover .__base {
      background: var(--gray-200);
    }

    &._Active {
      border-left: 8px solid var(--primary);
      padding-left: calc(1.5rem - 8px);

      .__base {
        background: var(--primary);
        color: var(--white);
      }

      .nav_icon {
        .__default {
          display: none;
        }

        .__active {
          display: unset;
        }
      }
    }
  }
}
