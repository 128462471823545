.DefaultHeaderContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 128px;
  padding: 36px 0px 12px 0px;
  gap: 24px;

  .SearchBarBox {
    flex: 1 auto auto;
    max-width: 900px;
  }

  .ProfileInfoBox {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    font-size: 18px;

    .__info {
      color: var(--body2);
      i {
        color: var(--primary);
        font-size: 24px;
      }
    }
  }

  .__Avatar {
    width: 42px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    border-radius: 50%;
    border: 1px solid var(--primary-500);
    color: var(--gray-300);
  }
}
